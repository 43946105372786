<template>
  <v-dialog :value="value" max-width="1000" scrollable persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <ValidationObserver ref="formObserver">
          <v-form :disabled="isLoading">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <vee-text-field v-model="item.id" label="Id" disabled />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-text-field
                    v-model="item.uniqueKey"
                    rules="required|max:128"
                    label="Unique key*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-text-field
                    v-model="item.name"
                    rules="max:128"
                    label="Name"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.category"
                    :rules="'required|oneOf:' + Object.values(SessionCategory).join(',')"
                    :items="[''].concat(Object.values(SessionCategory))"
                    label="Category*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.type"
                    :rules="'required|oneOf:' + Object.values(SessionType).join(',')"
                    :items="[''].concat(Object.values(SessionType))"
                    label="Type*"
                  />
                </v-col>

                <v-col cols="6" sm="3">
                  <vee-text-field
                    v-model="item.level"
                    rules="integer"
                    label="Level"
                    type="number"
                    min="0"
                  />
                </v-col>

                <v-col cols="6" sm="3">
                  <vee-text-field
                    v-model="item.unlockAt"
                    rules="double"
                    label="Unlock at"
                  />
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="item.exercises"
                    :loading="areExercisesLoading"
                    :items="exercises"
                    label="Exercises"
                    item-text="name"
                    item-value="uniqueKey"
                    return-object
                    multiple
                    clearable
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        close
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeExercise(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.id }} /
                          {{ data.item.uniqueKey }} /
                          <v-chip small>{{ data.item.category }}</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn text :loading="isLoading" @click="onSave">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDeleteOutline } from '@mdi/js'
import { computed, onMounted, ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { SessionCategory, SessionType } from '@/enums/enums'
import useExercises from '@/composables/api/exercises'

export default {
  components: {
    ValidationObserver,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { exercises, isLoading: areExercisesLoading, fetchExercises } = useExercises()

    const formObserver = ref(null)

    const formTitle = computed(() => (
      props.item.id === '' ? 'Create an session' : 'Edit the session'
    ))

    onMounted(async () => {
      await fetchExercises()
    })

    const onCancel = () => {
      emit('cancel')
    }

    const onSave = async () => {
      const isValid = await formObserver.value.validate()

      if (isValid) {
        const session = structuredClone(props.item)
        session.exercises = session.exercises.map(({ uniqueKey }) => ({ uniqueKey }))

        emit('save', session)
      }
    }

    const removeExercise = item => {
      const index = props.item.exercises.findIndex(value => item.uniqueKey === value.uniqueKey)

      if (index >= 0) {
        props.item.exercises.splice(index, 1)
      }
    }

    return {
      formObserver,
      formTitle,

      areExercisesLoading,
      exercises,

      onCancel,
      onSave,

      removeExercise,

      SessionType,
      SessionCategory,

      icons: {
        mdiDeleteOutline,
      },
    }
  },
}
</script>
