import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useSessions() {
  const RESOURCE = '/session'

  const { showSnackbar } = useSnackbar()

  const dtHeaders = [
    {
      text: 'UNIQUE KEY', value: 'uniqueKey', width: 240,
    },
    {
      text: 'NAME', value: 'name',
    },
    {
      text: 'CATEGORY', value: 'category', width: 120,
    },
    {
      text: 'TYPE', value: 'type', width: 120,
    },
    {
      text: 'LEVEL', value: 'level', width: 120,
    },
    {
      text: 'UNLOCK AT', value: 'unlockAt', width: 120,
    },
    {
      text: 'EXERCISES', value: 'exercises', width: 120,
    },
    {
      text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'center',
    },
  ]
  const dtSearchQuery = ref('')
  const dtExpanded = ref([])

  const blankSession = {
    id: '',
    uniqueKey: '',
    name: '',
    level: '',
    category: '',
    type: '',
    unlockAt: '',
    exercises: [],
  }

  const sessions = ref([])
  const session = ref(structuredClone(blankSession))

  const isLoading = ref(false)

  const createSession = async sessionItem => {
    isLoading.value = true

    await axios.post(RESOURCE, sessionItem)
      .then(() => {
        showSnackbar('You have successfully created a new session', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to create a new session:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  // eslint-disable-next-line no-unused-vars
  const deleteSession = async sessionItem => {
    // TODO: use API when it will be ready
  }

  const fetchSessions = async () => {
    isLoading.value = true

    await axios.get(RESOURCE)
      .then(response => {
        sessions.value = response.data
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch sessions:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateSession = async sessionItem => {
    isLoading.value = true

    await axios.put(RESOURCE, sessionItem)
      .then(() => {
        showSnackbar('You have successfully updated the session', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to update the session:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    dtHeaders,
    dtSearchQuery,
    dtExpanded,

    blankSession,
    sessions,
    session,

    isLoading,

    createSession,
    deleteSession,
    fetchSessions,
    updateSession,
  }
}
